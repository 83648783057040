<template>
    <div class="help_center">
        <NavBar navSelIndex="-1"></NavBar>
        <div class="header_img">
            <img src="../../../../public/icon/mall/helpCenter/helpcneter.jpg" alt="">
        </div>
        <b-container class="help_center_cont" fluid>
            <b-row class="help_center_row">
                <b-col class="help_center_left" sm="6" md="4" lg="4" xl="2">
                    <div class="title_list" v-for="(item,index) in helpCenterList" :key="index">
                        <span class="title_main">{{item.name}}</span>
                        <span @click="choose(it.helpcenter_id)" :class="helpcenter_id == it.helpcenter_id ? 'tltle_active':'tltle_item'" v-for="(it,ind) in item.list" :key="ind">{{it.title}}</span>
                    </div>
                </b-col>
                <b-col class="help_center_right" sm="6" md="8" lg="8" xl="10">
                    <div class="help_center_content">
                        <div class="content_name">
                            {{title}}
                        </div>
                        <div class="content" v-html="content">
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <Footer></Footer>
    </div>
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
    components: {
        NavBar,
        Footer,
    },
    data(){
        return{
            helpCenterList:[],
            helpcenter_id:1,
            title:'',
            content:''
        }
    },
    mounted(){
        this.getData()
        this.getInfo()
        if(this.$route.query.id){
            this.helpcenter_id = this.$route.query.id
        }
    },
    methods:{
        choose(id){
            this.helpcenter_id = id
            this.getInfo()
        },
        getData(){
            this.helpCenterList = [
                {
                    name:'新手指南',
                    list:[]
                },
                {
                    name:'售后服务',
                    list:[]
                },
                {
                    name:'购物指南',
                    list:[]
                },
                {
                    name:'关于我们',
                    list:[]
                }
            ]
            for(let i= 1;i<5;i++){
                this.getTypeData(i)
            }
        },
        getTypeData(index){
            this.$api.gettypes({type:index}).then((res)=>{
                if(res.status == 100){
                    switch(index){
                        case 1:
                            this.$set(this.helpCenterList[index-1],'list',res.data);
                            break;
                        case 2:
                            this.$set(this.helpCenterList[index-1],'list',res.data);
                            break;
                        case 3:
                            this.$set(this.helpCenterList[index-1],'list',res.data);
                            break;
                        case 4:
                            this.$set(this.helpCenterList[index-1],'list',res.data);
                    }
                }
            })
        },
        getInfo(){
            this.$api.getinfo({helpcenter_id:this.helpcenter_id}).then((res)=>{
                if(res.status == 100){
                    this.title = res.data.title
                    this.content = res.data.content
                    console.log(res)
                }
            })  
        }
    }
}
</script>
<style lang="scss" scoped>
      @import "@/view/mall/helpCenter/helpCenter";
</style>